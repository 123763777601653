import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

import sectionImg from 'assets/Benefit/Benefits-Image-3.png';
import {
  StyledText,
  StyledImage,
  StyledParagraph,
  DesktopView,
  MobileView,
  ResponsiveTitleFont,
} from 'components/common/styled';
import { Breakpoints } from 'utils/types/commons';
import { COLORS } from 'utils/constants/colors';

const Wrapper = styled.div`
  padding: 0 10rem 2rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 0 20rem;
  }
`;

const GettingSection = () => {
  return (
    <Wrapper>
      <DesktopView>
        <Row align="middle" wrap={false}>
          <Col push={2} span={10}>
            <Space size={16} direction="vertical">
              <Row>
                <Col>
                  <StyledText $size="52">
                    Getting You Paid.{' '}
                    <StyledText $color="#57CD66" $size="52">
                      Faster.{' '}
                    </StyledText>
                  </StyledText>
                </Col>
              </Row>

              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Space size={32} direction={'vertical'}>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      Dramatically reduce the likelihood of crime in and around your venues by operating completely cashless
                      events. With guaranteed age and biometric identity verification, plus coming soon the option to process
                      cryptocurrency payments, this truly is the future of payment processing and security.
                    </StyledParagraph>

                    <StyledParagraph $weight="400" $lineHeight="28">
                      Working alongside our merchant partner, we can also provide cleared funds as a next day service.
                    </StyledParagraph>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>
          <Col span={11} push={2}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
          </Col>
        </Row>
      </DesktopView>

      <MobileView>
        <Row align="middle">
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="80%" />
          </Col>
          <Col>
            <Space size={16} direction="vertical" style={{ textAlign: 'center' }}>
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <ResponsiveTitleFont $size="52">
                    Getting You Paid.{' '}
                    <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
                      Faster.{' '}
                    </ResponsiveTitleFont>
                  </ResponsiveTitleFont>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Space size={32} direction={'vertical'}>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      Dramatically reduce the likelihood of crime in and around your venues by operating completely cashless
                      events. With guaranteed age and biometric identity verification, plus coming soon the option to process
                      cryptocurrency payments, this truly is the future of payment processing and security.
                    </StyledParagraph>

                    <StyledParagraph $weight="400" $lineHeight="28">
                      Working alongside our merchant partner, we can also provide cleared funds as a next day service.
                    </StyledParagraph>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </MobileView>
    </Wrapper>
  );
};

export default GettingSection;
