import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { StyledContent } from 'components/common/styled';
import { Banner, DiveSection, Partners, PlugPlay, ShowSection, SolutionSection } from 'components/LandingPage';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\/support/)) {
      // @ts-ignore: type not found in window
      window.Intercom('show');
    }
  }, [location]);

  return (
    <StyledContent>
      <Banner />
      <PlugPlay />
      <SolutionSection />
      <ShowSection />
      <DiveSection />
      <Partners />
    </StyledContent>
  );
};

export default Home;
