import { Col, Row } from 'antd';
import styled from 'styled-components';

import { DesktopView, MobileView } from 'components/common/styled';
import { StyledFooter } from 'components/Footer/Styled';

import Demo from './Demo';
import GetStarted from './GetStarted';
import Logos from './Logos';
import Office from './Office';
import QuickLinks from './QuickLinks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Footer = () => {
  return (
    <StyledFooter>
      <DesktopView>
        <Row>
          <Col span={8} xl={7}>
            <Logos />
          </Col>
          <Col span={3}>
            <QuickLinks />
          </Col>
          <Col span={5}>
            <Office />
          </Col>
          <Col span={4}>
            <GetStarted />
          </Col>
          <Col span={4} xl={5}>
            <Demo />
          </Col>
        </Row>
      </DesktopView>

      <MobileView>
        <Container>
          <Logos />
          <QuickLinks />
          <Office />
          <GetStarted />
          <Demo />
        </Container>
      </MobileView>
    </StyledFooter>
  );
};

export default Footer;
