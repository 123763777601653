import { Typography, Image, Button, Divider, Layout, Space, Table } from 'antd';
import styled, { css } from 'styled-components';

import gradientBg1 from 'assets/gradient-bg-1.svg';
import { Breakpoints, StyledButtonProps, StyledTextProps } from 'utils/types/commons';
import { COLORS } from 'utils/constants/colors';

export const BannerContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: ${(props) => props.height || '100vh'};
`;

export const StyledContent = styled(Layout.Content)`
  background: transparent;
  padding: 0;
`;

export const OverlayContainer = styled.div`
  padding: 6rem 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 6rem 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 6rem 20rem;
  }
`;

export const ResponsiveContainer = styled(BannerContainer)`
  @media only screen and (max-width: 1240px) {
    height: 100%;
  }
`;

export const BannerBackground = styled.div<{ bgImage?: string }>`
  background: url(${(props) => props.bgImage || gradientBg1});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  top: 2rem;
  height: 100%;
  z-index: 0;
`;

export const StyledText = styled(Typography.Text)<StyledTextProps>`
  color: ${(props) => props.$color || COLORS.SHADES_WHITE};
  font-size: ${(props) => props.$size || 18}px;
  font-weight: ${(props) => props.$weight || '700'};
  line-height: ${(props) => props.$lineHeight || '72'}px;
`;

export const ResponsiveTitleFont = styled(StyledText)`
  font-size: 40px;
  line-height: 48px;

  @media only screen and (min-width: ${Breakpoints.sm}) {
    font-size: 48px;
    line-height: 56px;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    font-size: 52px;
    line-height: 60px;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    font-size: 60px;
    line-height: 72px;
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)<StyledTextProps>`
  color: ${(props) => props.$color || COLORS.SHADES_WHITE};
  font-size: ${(props) => props.$size || 18}px;
  font-weight: ${(props) => props.$weight || '500'};
  line-height: ${(props) => props.$lineHeight || 24}px;
  font-family: Inter, sans-serif;
  padding: 0 6px;
`;

export const StyledImage = styled(Image)`
  width: ${(props) => props.width || 20}px;
  height: ${(props) => props.height || 20}px;
  margin: 0px 4px;
`;

export const MobileButtonWrapper = styled(Space)`
  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    width: 85vw;

    .ant-space-item {
      width: 100%;
      > button {
        margin: 0;
        width: 100%;
      }
    }
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  width: ${(props) => props.$width || 160}px;
  height: ${(props) => props.$height || 50}px;
  border-radius: 8px;
  border-width: 2px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px ${(props) => props.$margin || 12}px;
  cursor: pointer;
  font-family: Inter, sans-serif;
  color: ${COLORS.SHADES_WHITE};

  ${(props) =>
    props.$variant === 'ghost' &&
    css`
      border-color: ${COLORS.NEUTRAL_GRAY_300};
      background-color: transparent !important;
      &:hover {
        color: ${COLORS.NEUTRAL_GRAY_300};
        border-color: ${COLORS.NEUTRAL_GRAY_300};
      }

      &:active,
      &:focus {
        color: ${COLORS.NEUTRAL_GRAY_200};
        border-color: ${COLORS.NEUTRAL_GRAY_200};
      }
    `};

  ${(props) =>
    props.$variant === 'primary' &&
    css`
      color: ${COLORS.SHADES_WHITE};
      background: ${COLORS.PRIMARY_500};
      border-color: ${COLORS.PRIMARY_500};

      &:hover {
        color: ${COLORS.SHADES_WHITE};
        background: ${COLORS.PRIMARY_300};
        border-color: ${COLORS.PRIMARY_300};
      }

      &:active,
      &:focus {
        color: ${COLORS.SHADES_WHITE};
        background: ${COLORS.PRIMARY_700};
        border-color: ${COLORS.PRIMARY_700};
      }
    `};

  ${(props) => {
    if (props.$variant === 'success')
      return css`
        background: ${COLORS.SUCCESS_500};
        color: ${COLORS.SHADES_WHITE};
        border-color: ${COLORS.SUCCESS_500};

        &:hover {
          color: ${COLORS.SHADES_WHITE};
          background: ${COLORS.SECONDARY_500};
          border-color: ${COLORS.SECONDARY_500};
        }

        &:active,
        &:focus {
          color: ${COLORS.SHADES_WHITE};
          background: ${COLORS.SECONDARY_500};
          border-color: ${COLORS.SECONDARY_500};
        }
      `;
  }};
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
  border-color: ${COLORS.NEUTRAL_GRAY_700};
  opacity: 0.2;
`;

export const DesktopView = styled.div`
  @media only screen and (max-width: ${Breakpoints.lg}) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media only screen and (min-width: ${Breakpoints.lg}) {
    display: none;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table {
    font-family: Inter, 'sans-serif';
    background: ${COLORS.DARK_MODE_900};
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
  }

  .ant-table-cell {
    background: ${COLORS.DARK_MODE_900};
    color: ${COLORS.SHADES_WHITE};
    border: 1px solid ${COLORS.NEUTRAL_GRAY_800};
    vertical-align: top;
    padding: 1em.5em;
  }

  .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-tbody {
    background: ${COLORS.DARK_MODE_900};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${COLORS.DARK_MODE_900};
  }

  .ant-table-tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  .ant-table-tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

export const BottomBannerBackground = styled.div<{
  bgImage?: string;
  $size?: string;
  $height?: string;
  $bottom?: string;
  $position?: string;
  $topMargin?: string;
}>`
  background: url(${(props) => props.bgImage || gradientBg1});
  background-size: ${(props) => props.$size || 'contain'};
  background-repeat: no-repeat;
  background-position: ${(props) => props.$position || 'bottom'};
  padding-bottom: ${(props) => props.$bottom || 0};
  height: ${(props) => props.$height || '100%'};
  width: 100%;
  margin-top: ${(props) => props.$topMargin || 0};
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledBullet = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${COLORS.NEUTRAL_GRAY_900};
  border-radius: 50%;
  display: inline-block;
`;
