import { ButtonProps } from "antd"
import { ColorsType } from "utils/constants/colors"

export interface StyledTextProps {
    $color?: string
    $size?: string | number
    $weight?: number | string
    $lineHeight?: number | string
}

export interface StyledButtonProps extends ButtonProps {
    $width?: number
    $height?: number
    $borderColor?: string
    $color?: string
    $variant?: ColorsType
    $hoverBorderColor?: string
    $hoverBackgroundColor?: string
    $margin?: number
}

export interface OverlayContainerProps extends ButtonProps {
    $screen?: any,
    $size?: string
}

export enum Breakpoints {
    xs = '480px',
    sm = '576px',
    md = '768px',
    lg = '992px',
    xl = '1200px',
    xxl = '1600px',
}

export enum Links {
    GOOGLE = 'https://play.google.com/store/apps/details?id=com.matchfitpass.android',
    APPLE = 'https://apps.apple.com/gb/app/match-fit-pass/id1560988662',
    DENSO = 'https://www.denso-wave.com/en/',
    ZKTECO = 'https://www.zkteco.com/en/'
}