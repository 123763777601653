import { StyledContent } from 'components/common/styled';
import { PolicyContent, PolicyHeader } from 'components/Policy';

const PrivacyPolicy = () => {
  return (
    <StyledContent>
      <PolicyHeader page="policy" />
      <PolicyContent />
    </StyledContent>
  );
};

export default PrivacyPolicy;
