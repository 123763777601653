export enum COLORS {
    DARK_MODE_900 = '#1C1C25',
    SHADES_WHITE = '#FFFFFF',

    SECONDARY_500 = '#57CD66',
    SUCCESS_500 = '#00B75F',

    PRIMARY_50 = '#F1F1FB',
    PRIMARY_100 = '#DDDBF4',
    PRIMARY_200 = '#BBB7EA',
    PRIMARY_300 = '#928DDD',
    PRIMARY_400 = '#5449C5',
    PRIMARY_500 = '#463DA3',
    PRIMARY_700 = '#2E2E72',
    
    NEUTRAL_GRAY_100 = '#E5E5EA',
    NEUTRAL_GRAY_200 = '#CDCDD4',
    NEUTRAL_GRAY_300 = '#B4B4BC',
    NEUTRAL_GRAY_400 = '#9E9EA8',
    NEUTRAL_GRAY_700 = '#535361',
    NEUTRAL_GRAY_800 = '#3D3D4A',
    NEUTRAL_GRAY_900 = '#535361'
}

export type ColorsType = 'primary' | 'default' | 'ghost' | 'success' | 'secondary'

// interface iColor  {
//     [key: string]: {
//         50: string
//         100: string
//         200: string
//         300: string
//         400: string
//         500: string
//         900: string
//     }
// }

// export const COLORS: iColor = {
//     PRIMARY: {
//         50: '#F1F1FB',
//         100: '#DDDBF4',
//         200: '#BBB7EA',
//         300: '#928DDD',
//         400: '#5449C5',
//         500: '#463DA3',
//         900: ''
//     },
//     NEUTRAL_GRAY: {
//         50: '',
//         100: '#E5E5EA',
//         200: '#CDCDD4',
//         300: '',
//         400: '#9E9EA8',
//         500: '',
//         900: '#535361'
//     },
//     SECONDARY: {
//         50: '',
//         100: '#E5E5EA',
//         200: '#CDCDD4',
//         300: '',
//         400: '#9E9EA8',
//         500: '#57CD66',
//         900: '#535361'
//     },
//     SUCCESS: {
//         50: '',
//         100: '#E5E5EA',
//         200: '#CDCDD4',
//         300: '',
//         400: '#9E9EA8',
//         500: '#00B75F',
//         900: '#535361'
//     },
//     SHADES_WHITE: {
//         50: '',
//         100: '#E5E5EA',
//         200: '#CDCDD4',
//         300: '',
//         400: '#9E9EA8',
//         500: '#FFFFFF',
//         900: '#1C1C25'
//     },
//     DARK_MODE: {
//         50: '',
//         100: '#E5E5EA',
//         200: '#CDCDD4',
//         300: '',
//         400: '#9E9EA8',
//         500: '#00B75F',
//         900: '#1C1C25'
//     }
// }
