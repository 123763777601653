import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import { DesktopView, MobileView, StyledText } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  > span:first-of-type {
    padding: 1rem 0;
  }
`;

const Office = () => {
  const screens = useBreakpoint();
  const renderAddress = () => {
    return (
      <>
        Hubb, Lord Street, <br />
        Douglas, <br />
        Isle of Man,
        <br />
        IM1 4LN
      </>
    );
  };

  return (
    <>
      <DesktopView>
        <Row style={{ padding: '0px 12px' }}>
          <Space direction="vertical" size={16}>
            <Col span={24}>
              <StyledText $weight="700" $size={screens.xl ? '20' : '16'} $lineHeight="30" $color={COLORS.SECONDARY_500} style={{ whiteSpace: 'nowrap' }}>
                Registered Office
              </StyledText>
            </Col>
            <Col>
              <StyledText $weight="500" $size={screens.xl ? '16' : '12'} $lineHeight="24">
                {renderAddress()}
              </StyledText>
            </Col>
          </Space>
        </Row>
      </DesktopView>

      <MobileView>
        <Wrapper>
          <StyledText $weight="700" $size="20" $lineHeight="30" $color={COLORS.SECONDARY_500}>
            Registered Office
          </StyledText>
          <StyledText $weight="500" $size="16" $lineHeight="24">
            {renderAddress()}
          </StyledText>
        </Wrapper>
      </MobileView>
    </>
  );
};

export default Office;
