import styled from 'styled-components';
import { Typography, Image, Layout, Button } from 'antd';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints, StyledButtonProps } from 'utils/types/commons';
const { Text } = Typography;

export const Container = styled.div`
  padding: 26px 120px;
`;

export const StyledHeader = styled(Layout.Header)`
  padding: 0 10rem;
  margin: 13px 0;
  z-index: 2;
  background: transparent;

  @media (max-width: ${Breakpoints.xl}) {
    padding: 0 5rem;
  }

  @media (max-width: ${Breakpoints.lg}) {
    padding: 0 2rem;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    padding: 0 20rem;
  }
`

export const HeaderStyledImage = styled(Image)`
  width: ${(props) => props.width || 14}px;
  height: ${(props) => props.height || 14}px;
  margin: 0px 4px;
  cursor: pointer;
`;

export const HeaderStyledText = styled(Text)<{ $isActive?: boolean }>`
  color: ${(props) => (props.$isActive ? '#57CD66' : '#FFFFFF')};
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  margin: 0px 12px;
  cursor: pointer;
`;

interface HeaderDrawerButtonProps extends StyledButtonProps {
  $isActive: boolean
}

export const HeaderDrawerButton = styled(Button)<HeaderDrawerButtonProps>`
  border-radius: 8px;
  display: flex;
  width: 100%;
  height: auto;
  border: none;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  background: ${(props) => props.$isActive ? COLORS.PRIMARY_400 : 'transparent'};
  font-family: Inter, sans-serif;
  color: ${COLORS.SHADES_WHITE};

  &:hover, &:focus, &:active {
    background: ${COLORS.PRIMARY_400};
    color: ${COLORS.SHADES_WHITE};
  }
`;