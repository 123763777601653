/* eslint-disable react-hooks/rules-of-hooks */
import { Row, Col, Space, Grid } from 'antd';
import Parser from 'html-react-parser';

import { StyledText, StyledTable, StyledParagraph, BottomBannerBackground } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
import PolicyJson from './Policy.json';

const { useBreakpoint } = Grid;

const renderSectionTitle = (title: string) => {
  return (
    <StyledText $color={COLORS.SUCCESS_500} $size="32" $lineHeight="40">
      {title}
    </StyledText>
  );
};

const renderNormalText = (text: string, size: number = 18, lineHeight: number = 28) => {
  return (
    <StyledParagraph $weight="400" $size={size} $lineHeight={lineHeight} style={{ padding: 0, margin: 0 }}>
      {Parser(text)}
    </StyledParagraph>
  );
};

const renderText700 = (text: string, size: number = 18, lineHeight: number = 18) => {
  return (
    <StyledText $size={size} $lineHeight={lineHeight}>
      {Parser(text)}
    </StyledText>
  );
};

const renderSubSections = ({ number, title, description, isLabelLong = false, content = [] }: any) => {
  const screen = useBreakpoint();
  return (
    <Col span={24}>
      <Row gutter={[8, 16]}>
        <Col xs={{ span: isLabelLong === 'true' && screen.xs ? 6 : 4 }} md={{ span: isLabelLong === 'true' ? 3 : 2 }}>
          {renderText700(number)}
        </Col>
        <Col xs={{ span: isLabelLong === 'true' && screen.xs ? 18 : 20 }} md={{ span: isLabelLong === 'true' ? 21 : 22 }}>
          <Row gutter={[0, 8]}>
            {title && <Col span={24}>{renderText700(title)}</Col>}
            {Boolean(description) ? (
              <Col span={24}>{renderNormalText(description)}</Col>
            ) : (
              content.map((subContent: any) => {
                const { type } = subContent;
                const itemNo = isNaN(number) ? number : number - 1;
                return renderContent(type, subContent, itemNo);
              })
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const renderBulletList = (lists: any) => {
  return (
    <Col span={24}>
      {lists.map((list: any, i: number) => {
        return (
          <Row gutter={[8, 16]} key={`bulletlist${i}`}>
            <Col span={1}>
              <div
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: COLORS.NEUTRAL_GRAY_900,
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              ></div>
            </Col>
            <Col span={23}>
              {list.map((sub: any, j: number) => {
                if (sub?.title) {
                  return (
                    <Col span={24} key={`sublist${j}`}>
                      <Row>
                        <Col span={24}>{renderNormalText(sub.title)}</Col>
                        <Col span={24}>
                          {sub.subcontent.map((bullet: any, i: number) => {
                            return (
                              <Row key={`subbullet${i}`}>
                                <Col span={1}>
                                  <div
                                    style={{
                                      height: '0',
                                      width: '0',
                                      borderTop: '6px solid transparent',
                                      borderBottom: '6px solid transparent',
                                      borderLeft: `8px solid ${COLORS.NEUTRAL_GRAY_900}`,
                                      marginTop: '8px',
                                    }}
                                  ></div>
                                </Col>
                                <Col span={23}>{renderNormalText(bullet)}</Col>
                              </Row>
                            );
                          })}
                        </Col>
                      </Row>
                    </Col>
                  );
                }
                return (
                  <Col span={24} key={`textSubList${j}`}>
                    {renderNormalText(sub)}
                  </Col>
                );
              })}
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

const renderTable = (table: any) => {
  const screens = useBreakpoint();
  const { columns, data } = table[0];
  const formattedColums = columns.map((col: any) => {
    return {
      ...col,
      render: (text: any) => <div key={col.key}>{renderNormalText(text, screens.xs ? 10 : 14, 20)}</div>,
    };
  });
  return (
    <Col span={24}>
      <StyledTable
        style={{ background: COLORS.DARK_MODE_900 }}
        columns={formattedColums}
        dataSource={data}
        pagination={false}
      />
    </Col>
  );
};

const renderContent = (type: any, subContent: any, i: number) => {
  if (type === 'text700') {
    return subContent.content.map((text: string | any) => {
      return (
        <Col span={24} key={'policynormal700' + i}>
          {renderText700(text)}
        </Col>
      );
    });
  }

  if (type === 'bulletList') {
    return (
      <Col span={24} key={'policybullet' + i}>
        {renderBulletList(subContent.content)}
      </Col>
    );
  }

  if (type === 'table') {
    return (
      <Col span={24} key={'table' + i}>
        {renderTable(subContent.content)}
      </Col>
    );
  }

  if (type === 'subSections') {
    return subContent.content.map((sections: any, j: number) => {
      const itemNumber = +i + 1;
      return renderSubSections({
        number: `${isNaN(itemNumber) ? i : itemNumber}.${+j + 1}`,
        ...sections,
      });
    });
  }
  return subContent.content.map((text: any, i: number) => {
    return (
      <Col span={24} key={'policynormaltext' + i}>
        {renderNormalText(text)}
      </Col>
    );
  });
};

const PolicyContent = () => {
  const policy = Object.values(PolicyJson);
  const screen = useBreakpoint();
  return (
    <BottomBannerBackground bgImage={screen.xs && mobileBg}>
      <Row style={{ padding: `4rem 0 12rem 0` }}>
        <Col
           xs={{ span: 20, offset: 2 }}
           sm={{ span: 18, offset: 3 }}
           md={{ span: 16, offset: 4 }}
           lg={{ span: 16, offset: 4 }}
           xl={{ span: 14, offset: 5 }}
        >
          <Row>
            <Space direction="vertical" size={40}>
              {policy.map(({ key, label, content }, i) => {
                return (
                  <Row gutter={[0, 16]} key={`policy${key}`}>
                    <Col span={24}>{renderSectionTitle(`${+i + 1}. ${label}`)}</Col>
                    {content.map((subContent: { type: any }) => {
                      const { type } = subContent;
                      return renderContent(type, subContent, i);
                    })}
                  </Row>
                );
              })}
              <Row gutter={[0, 16]}>
                <Col span={24}>{renderSectionTitle('Glossary')}</Col>
                <Col span={24}>{renderText700('LAWFUL BASIS')}</Col>
                <Col span={24}>
                  {renderNormalText(
                    '<b>Consent</b> means processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us.'
                  )}
                </Col>
                <Col span={24}>
                  {renderNormalText(
                    '<b>Legitimate Interest</b> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.'
                  )}
                </Col>
                <Col span={24}>
                  {renderNormalText(
                    '<b>Performance of Contract</b> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.'
                  )}
                </Col>
                <Col span={24}>
                  {renderNormalText(
                    '<b>Comply with a legal obligation</b> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.'
                  )}
                </Col>
              </Row>
              <Row gutter={[0, 16]}>
                <Col span={24}>{renderSectionTitle('Third Parties')} </Col>
                <Col span={24}>{renderText700('Internal Third Parties')} </Col>
                <Col span={24}>
                  {renderNormalText(
                    'Other companies in the Verified Technologies Group acting as joint controllers or processors and who are based in the United Kingdom. '
                  )}{' '}
                </Col>
                <Col span={24}>{renderText700('External Third Parties')} </Col>
                <Col span={24}>{renderNormalText('Service providers acting as processors based in the UK.')} </Col>
                <Col span={24}>
                  {renderNormalText(
                    'Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the UK who provide consultancy, banking, legal, insurance and accounting services.'
                  )}{' '}
                </Col>
                <Col span={24}>
                  {renderNormalText(
                    'HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.'
                  )}{' '}
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
    </BottomBannerBackground>
  );
};

export default PolicyContent;
