import { Col, Row, Menu, Space, Dropdown, Drawer } from 'antd';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

import { ReactComponent as PlayStoreLogo } from 'assets/commons/play-store-icon.svg';
import { ReactComponent as AppleStoreLogo } from 'assets/commons/apple-store-icon.svg';
import mfp_logo from 'assets/LandingPage/InfoHeader/MFPLogoSmall.png';
import mfpLogo from 'assets/mfp-logo.svg';
import { StyledButton, StyledParagraph, DesktopView, MobileView } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Links } from 'utils/types/commons';
import { scrollToTop } from 'utils/scroll/scrollTop';
import { HeaderStyledImage, HeaderStyledText, StyledHeader, HeaderDrawerButton } from './Styled';

type Data2d = {
  x: number;
  y: number;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HamburgerMenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  cursor: pointer;
`;

const tabs = [
  { label: 'The Technology', key: '/technology' },
  { label: 'The Benefit', key: '/benefits' },
];

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta: Data2d) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

const Header = () => {
  Scrollbar.use(ModalPlugin);
  const scrollBar = Scrollbar.init(document.body);

  const location = useLocation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleNavigation = (key: string) => {
    navigate(key);
    scrollToTop();
  };

  const menuItems = useCallback((size = 14) => {
    return [
      {
        label: (
          <StyledParagraph $weight={700} $size={size} $lineHeight={20} style={{ marginBottom: 0 }}>
            Download via Google Play
          </StyledParagraph>
        ),
        key: 'google',
        icon: <PlayStoreLogo />,
      },
      {
        label: (
          <StyledParagraph $weight={700} $size={size} $lineHeight={20} style={{ marginBottom: 0 }}>
            Download via App Store
          </StyledParagraph>
        ),
        key: 'apple',
        icon: <AppleStoreLogo fill={COLORS.SHADES_WHITE} />,
      },
    ];
  }, []);

  const handleMenuClick = (item: { key: string; keyPath: string[]; domEvent: SyntheticEvent }) => {
    if (item.key === 'google') {
      window.open(Links.GOOGLE, '_blank');
    } else {
      window.open(Links.APPLE, '_blank');
    }
  };

  const handleDemo = () => {
    // @ts-ignore: type not found in window
    window.Intercom('show');
  };

  const menuOverlay = () => (
    <Menu
      theme="dark"
      style={{
        padding: '20px 8px',
        width: '100%',
        borderRadius: 8,
        background: COLORS.PRIMARY_500,
        color: COLORS.SHADES_WHITE,
      }}
      onClick={handleMenuClick}
      items={menuItems()}
    />
  );

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const onDrawerButtonClick = (locationString: string) => {
    toggleDrawer();
    handleNavigation(locationString);
  };

  useEffect(() => {
    scrollBar.updatePluginOptions('modal', { open: openDrawer });
  }, [scrollBar, openDrawer]);

  return (
    <StyledHeader>
      {/* Tablet and Phone */}
      <MobileView>
        <Container>
          <HeaderStyledImage src={mfpLogo} height="50" width="168" preview={false} onClick={() => handleNavigation('/')} />
          <HamburgerMenuContainer onClick={toggleDrawer}>
            <span className="material-icons" style={{ color: COLORS.SHADES_WHITE }}>
              menu
            </span>
          </HamburgerMenuContainer>
        </Container>

        <Drawer
          title={
            <HeaderStyledImage src={mfpLogo} height="100%" width="100%" preview={false} onClick={() => handleNavigation('/')} />
          }
          placement="left"
          onClose={toggleDrawer}
          open={openDrawer}
          width='360px'
          headerStyle={{
            borderBottom: 'none',
            padding: '12px',
          }}
          style={{
            opacity: 0.98,
            // this is to surpass Intercom's zIndex
            zIndex: 10000000000,
          }}
          drawerStyle={{
            backgroundColor: COLORS.PRIMARY_500,
            color: COLORS.SHADES_WHITE,
            padding: '3rem 0',
            boxShadow: '8px 8px 18px rgba(31, 29, 76, 0.16)',
          }}
          closable={false}
          key="mobile-drawer"
          footerStyle={{
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
          footer={
            <StyledButton
              onClick={() => {
                handleDemo();
                toggleDrawer();
              }}
              style={{ margin: 0, width: '100%' }}
              $variant="success"
            >
              Book a Demo
            </StyledButton>
          }
          extra={
            <StyledButton
              type="text"
              onClick={toggleDrawer}
              style={{
                background: 'transprent',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                margin: 0,
              }}
            >
              <span className="material-icons" style={{ color: COLORS.SHADES_WHITE }}>
                close
              </span>
            </StyledButton>
          }
        >
          {/* Drawer Content */}
          <Space direction="vertical" style={{ width: '100%' }}>
            <HeaderDrawerButton $isActive={'/' === location.pathname} onClick={() => onDrawerButtonClick('/')}>
              Home
            </HeaderDrawerButton>
            {tabs.map(({ key, label }) => {
              return (
                <HeaderDrawerButton key={key} $isActive={key === location.pathname} onClick={() => onDrawerButtonClick(key)}>
                  {label}
                </HeaderDrawerButton>
              );
            })}

            <Menu
              mode="inline"
              theme="dark"
              style={{
                width: '100%',
                background: 'transparent',
                padding: 0,
                color: COLORS.SHADES_WHITE,
              }}
              inlineIndent={16}
              onClick={handleMenuClick}
              items={[
                {
                  key: 'getStarted',
                  label: 'Get Started',
                  children: menuItems(16).map((item) => {
                    return {
                      ...item,
                      style: {
                        padding: '16px',
                        margin: 0,
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'Inter, sans-serif',
                        color: COLORS.SHADES_WHITE,
                        background: COLORS.PRIMARY_500,
                      },
                    };
                  }),
                  style: {
                    borderRadius: 8,
                    padding: 0,
                    margin: 0,
                    fontSize: '18px',
                    fontWeight: '600',
                    fontFamily: 'Inter, sans-serif',
                    color: COLORS.SHADES_WHITE,
                  },
                },
              ]}
            />
          </Space>
        </Drawer>
      </MobileView>

      {/* Desktop View */}
      <DesktopView>
        <Row wrap={false} align="middle" justify={'space-between'}>
          <Col>
            <Row align="middle" gutter={15} wrap={false}>
              <Col span={5}>
                <HeaderStyledImage
                  src={mfp_logo}
                  height="100%"
                  width="100%"
                  preview={false}
                  onClick={() => handleNavigation('/')}
                />
              </Col>
              <Col offset={1}>
                {tabs.map(({ key, label }) => {
                  return (
                    <HeaderStyledText key={key} $isActive={key === location.pathname} onClick={() => handleNavigation(key)}>
                      {label}
                    </HeaderStyledText>
                  );
                })}
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Dropdown overlay={menuOverlay} trigger={['click']} overlayStyle={{ position: 'fixed' }}>
                <StyledButton $variant="ghost">
                  <Space align="center" size={6}>
                    Get Started{' '}
                    <span className="material-icons md-32" style={{ display: 'flex' }}>
                      expand_more
                    </span>
                  </Space>
                </StyledButton>
              </Dropdown>
              <StyledButton onClick={handleDemo} style={{ margin: 0 }} $variant="success">
                Book a Demo
              </StyledButton>
            </Row>
          </Col>
        </Row>
      </DesktopView>
    </StyledHeader>
  );
};

export default Header;
