import { Col, Row } from 'antd';
import styled from 'styled-components';

import { DesktopView, MobileView, StyledImage } from 'components/common/styled';
import { Socials } from 'components/common/Socials';

import mfp_logo from 'assets/LandingPage/InfoHeader/MFPLogoSmall.png';
import densoSolution from 'assets/Technology/Denso-Wave-SQRC-Solutions.png';
import cyberEssential from 'assets/Cyber-essentials-Certivified-Check.png';

const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const Logos = () => {
  return (
    <>
      <DesktopView>
        <Row>
          <Col span={24} style={{ marginBottom: 16 }}>
            <StyledImage src={mfp_logo} height="58" width="191" preview={false} />
          </Col>
          <Col>
            <StyledImage preview={false} src={densoSolution} height="60" width="199" />
          </Col>
          <Col>
            <StyledImage preview={false} src={cyberEssential} height="60" width="51" />
          </Col>
          <Col span={24} style={{ marginTop: 16 }}>
            <Socials source="footer" />
          </Col>
        </Row>
      </DesktopView>

      <MobileView>
        <LogosContainer>
          <ImageWrapper>
            <StyledImage src={mfp_logo} height="58" width="191" preview={false} />
          </ImageWrapper>
          <ImageWrapper>
            <StyledImage preview={false} src={densoSolution} height="60" width="199" />
            <StyledImage preview={false} src={cyberEssential} height="60" width="51" />
          </ImageWrapper>
        </LogosContainer>
      </MobileView>
    </>
  );
};

export default Logos;
