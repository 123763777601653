import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import googlePlay from 'assets/LandingPage/SectionOne/Google_Play_Store_badge.png';
import appleStore from 'assets/LandingPage/SectionOne/App_Store_Badge.png';
import { StyledText, StyledImage, DesktopView, MobileView } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints, Links } from 'utils/types/commons';
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0;
  row-gap: 1rem;
`;

const ImageWrapper = styled(Space)`
  justify-content: center;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const GetStarted = () => {
  const screens = useBreakpoint();
  const handleRedirect = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <>
      <DesktopView>
        <Row>
          <Space direction="vertical" size={16}>
            <Col span={24}>
              <StyledText $weight="700" $size={screens.xl ? '20' : '16'} $lineHeight="30" $color={COLORS.SECONDARY_500}>
                Get Started
              </StyledText>
            </Col>
            <Col span={24}>
              <StyledImage
                onClick={() => handleRedirect(Links.GOOGLE)}
                preview={false}
                src={googlePlay}
                style={{ cursor: 'pointer' }}
                height={screens.xl ? '48' : '40'}
                width="90%"
              />
            </Col>
            <Col span={24}>
              <StyledImage
                onClick={() => handleRedirect(Links.APPLE)}
                preview={false}
                src={appleStore}
                style={{
                  cursor: 'pointer',
                }}
                height={screens.xl ? '48' : '40'}
                width="90%"
              />
            </Col>
          </Space>
        </Row>
      </DesktopView>

      <MobileView>
        <Wrapper>
          <StyledText $weight="700" $size="20" $lineHeight="30" $color={COLORS.SECONDARY_500}>
            Get Started
          </StyledText>
          <ImageWrapper size={16}>
            <StyledImage
              onClick={() => handleRedirect(Links.GOOGLE)}
              preview={false}
              src={googlePlay}
              style={{ cursor: 'pointer' }}
              height={screens.xs ? '72' : '64'}
              width={screens.xs ? '240' : '214'}
            />
            <StyledImage
              onClick={() => handleRedirect(Links.APPLE)}
              preview={false}
              src={appleStore}
              style={{
                cursor: 'pointer',
              }}
              height={screens.xs ? '72' : '64'}
              width={screens.xs ? '240' : '214'}
            />
          </ImageWrapper>
        </Wrapper>
      </MobileView>
    </>
  );
};

export default GetStarted;
