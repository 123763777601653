import { Space, Grid } from 'antd';
import { DesktopView, MobileView, StyledImage, StyledParagraph } from 'components/common/styled';

import infomail from 'assets/LandingPage/InfoHeader/icon-letter.png';

const { useBreakpoint } = Grid;

const InfoMail = () => {
  const screens = useBreakpoint();

  return (
    <div onClick={() => (window.location.href = 'mailto:info@matchfitpass.com')} style={{ cursor: 'pointer' }}>
      <DesktopView>
        <Space align="center" style={{ gap: screens.xl ? '8px' : '0' }}>
          <StyledImage src={infomail} height="12.25" width="14" preview={false} />
          <StyledParagraph $size={screens.xl ? '14': '10'} $lineHeight="20" style={{ margin: 0 }}>
            info@matchfitpass.com
          </StyledParagraph>
        </Space>
      </DesktopView>
      <MobileView>
        <Space align="center">
          <StyledImage src={infomail} height="12.25" width="14" preview={false} />
          <StyledParagraph $size="14" $lineHeight="20" style={{ padding: 0, margin:0 }}>
            info@matchfitpass.com
          </StyledParagraph>
        </Space>
      </MobileView>
    </div>
  );
};

export default InfoMail;
