import React, { createContext, useState } from "react";

export const DashboardContext = createContext();

const DashboardContextProvider = ({ children }) => {
    const [currentMenu, setCurrentMenu] = useState(0);

    return (
        <DashboardContext.Provider value={[currentMenu, setCurrentMenu]}>
            {children}
        </DashboardContext.Provider>

    )
}

export default DashboardContextProvider;