import { Col, Row, Space, Grid } from 'antd';
import {
  StyledText,
  StyledImage,
  StyledButton,
  StyledParagraph,
  MobileView,
  MobileButtonWrapper,
  ResponsiveTitleFont,
  BottomBannerBackground
} from 'components/common/styled';
import mobileBg from 'assets/mobile-rotate-bottom.svg';
import tabletBg from 'assets/tablet-rotate-bg.svg';
import sectionImg from 'assets/LandingPage/SectionFour.png';
import gradientBg2 from 'assets/gradient-bg-2.svg';
import { COLORS } from 'utils/constants/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';
import { scrollToTop } from 'utils/scroll/scrollTop';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 6rem 10rem 3rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 5rem 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 10rem 20rem 4rem;
  }
`;

const ShowSection = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleNavigate = (link: string) => {
    navigate(link);
    scrollToTop();
  };

  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground
            bgImage={screens.xs ? mobileBg : tabletBg}
            $size={`${screens.xs ? 'cover' : 'contain'}`}
            $position="top"
          >
            <Wrapper>
              <Row align="middle" justify="center">
                <Col>
                  <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
                </Col>

                <Col>
                  <Space direction="vertical" size={36} style={{ textAlign: 'center' }}>
                    <ResponsiveTitleFont $size="52">
                      Show Me{' '}
                      <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
                        The Money.
                      </ResponsiveTitleFont>
                    </ResponsiveTitleFont>

                    <Space direction="vertical" size={16}>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ padding: 0, margin: 0 }}>
                        Getting you paid faster with ultra-secure payments & no spending limits.
                      </StyledParagraph>

                      <MobileButtonWrapper>
                        <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost" style={{ margin: 0 }}>
                          The Technology
                        </StyledButton>
                        <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                          The Benefits
                        </StyledButton>
                      </MobileButtonWrapper>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground bgImage={gradientBg2} $size={screens.xl ? 'cover' : 'contain'} $position="top">
        <Wrapper>
          <Row align="middle">
            <Col span={12} offset={2}>
              <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
            </Col>
            <Col span={10} style={{ padding: '12px' }}>
              <Space size={16} direction="vertical">
                <Row>
                  <Col>
                    <StyledText $size="52">
                      Show Me{' '}
                      <StyledText $color={COLORS.SECONDARY_500} $size="52">
                        The Money.
                      </StyledText>
                    </StyledText>
                  </Col>
                </Row>

                <Space size={32} direction="vertical">
                  <Row>
                    <Col style={{ marginRight: 20 }}>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ padding: 0, margin: 0 }}>
                        Getting you paid faster with ultra-secure payments & no spending limits.
                      </StyledParagraph>
                    </Col>
                  </Row>

                  <Row>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost">
                        The Technology
                      </StyledButton>
                      <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                        The Benefits
                      </StyledButton>
                    </span>
                  </Row>
                </Space>
              </Space>
            </Col>
          </Row>
        </Wrapper>
      </BottomBannerBackground>
    );
  };

  return renderContent();
};

export default ShowSection;
