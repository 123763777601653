import { Col, Row, Space, Grid } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { scrollToTop } from 'utils/scroll/scrollTop';
import { DesktopView, MobileView, StyledParagraph, StyledText } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  > span {
    padding: 1rem 0;
  }
`;

const QuickLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const links = [
    {
      label: 'The Technology',
      isTab: true,
      link: '',
      key: '/technology',
    },
    {
      label: 'The Benefit',
      isTab: true,
      link: '',
      key: '/benefits',
    },
    {
      label: 'Terms Of Use',
      isTab: true,
      link: '',
      key: '/terms',
    },
    {
      label: 'Privacy Policy',
      isTab: true,
      link: '',
      key: '/privacy',
    },
  ];

  const navigateTo = (link: string) => {
    console.log(link);
  };

  const handleOnClickTabs = (key: string) => {
    navigate(key);
    scrollToTop();
  };

  return (
    <>
      <DesktopView>
        <Row>
          <Space direction="vertical" size={16}>
          <Col span={24}>
              <StyledText $weight="700" $size={screens.xl ? '20' : '16'} $lineHeight="30" $color={COLORS.SECONDARY_500} style={{ whiteSpace: 'nowrap'}}>
                Quick Links
              </StyledText>
            </Col>
            <Col span={24}>
              <Row>
                {links.map(({ label, key, isTab, link }) => {
                  return (
                    <Col span={24} key={key}>
                      <StyledParagraph
                        $weight="500"
                        $size={screens.xl ? '16' : '12'}
                        $lineHeight="24"
                        style={{
                          cursor: 'pointer',
                          marginBottom: 0,
                          color: key === location.pathname ? COLORS.SECONDARY_500 : COLORS.SHADES_WHITE,
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => (isTab ? handleOnClickTabs(key) : navigateTo(link))}
                      >
                        {label}
                      </StyledParagraph>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Space>
        </Row>
      </DesktopView>

      <MobileView>
        <Wrapper>
          <StyledText $weight="700" $size="20" $lineHeight="30" $color={COLORS.SECONDARY_500}>
            Quick Links
          </StyledText>
          {links.map(({ label, key, isTab, link }) => {
            return (
              <StyledParagraph
                key={key}
                $weight="500"
                $size="16"
                $lineHeight="24"
                style={{
                  cursor: 'pointer',
                  marginBottom: 0,
                  color: key === location.pathname ? COLORS.SECONDARY_500 : COLORS.SHADES_WHITE,
                }}
                onClick={() => (isTab ? handleOnClickTabs(key) : navigateTo(link))}
              >
                {label}
              </StyledParagraph>
            );
          })}
        </Wrapper>
      </MobileView>
    </>
  );
};

export default QuickLinks;
