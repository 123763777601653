import { Col, Row, Space } from 'antd';
import {
  StyledButton,
  StyledText,
  StyledImage,
  StyledParagraph,
  DesktopView,
  MobileView,
  MobileButtonWrapper,
  ResponsiveTitleFont,
} from 'components/common/styled';
import { scrollToTop } from 'utils/scroll/scrollTop';
import sectionImg from 'assets/LandingPage/SectionFive.png';
import { COLORS } from 'utils/constants/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 20px 1rem 20px 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 2rem 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 0 10rem 20px 20rem;
  }
`;

const DiveSection = () => {
  const navigate = useNavigate();

  const handleNavigate = (link: string) => {
    navigate(link);
    scrollToTop();
  };

  return (
    <Wrapper>
      <MobileView>
        <Row align="middle" justify="center">
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
          </Col>

          <Col>
            <Space direction="vertical" size={32} style={{ textAlign: 'center' }}>
              <ResponsiveTitleFont $size="52">
                Dive Into{' '}
                <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size="52">
                  Data
                </ResponsiveTitleFont>
              </ResponsiveTitleFont>

              <Space direction="vertical" size={16} align="center">
                <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                  Consolidate all your analytics platforms into a single point of truth with in-depth reporting & analytics.
                </StyledParagraph>

                <MobileButtonWrapper>
                  <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost" style={{ margin: 0 }}>
                    The Technology
                  </StyledButton>
                  <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                    The Benefits
                  </StyledButton>
                </MobileButtonWrapper>
              </Space>
            </Space>
          </Col>
        </Row>
      </MobileView>

      <DesktopView>
        <Row align="middle">
          <Col span={10}>
            <Space direction="vertical" size={32}>
              <Space direction="vertical" size={16}>
                <Row>
                  <Col>
                    <StyledText $size="52">
                      Dive Into{' '}
                      <StyledText $color={COLORS.SUCCESS_500} $size="52">
                        Data
                      </StyledText>
                    </StyledText>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginRight: 20 }}>
                    <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                      Consolidate all your analytics platforms into a single point of truth with in-depth reporting & analytics.
                    </StyledParagraph>
                  </Col>
                </Row>
              </Space>

              <Row>
                <span style={{ whiteSpace: 'nowrap' }}>
                  <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost">
                    The Technology
                  </StyledButton>
                  <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                    The Benefits
                  </StyledButton>
                </span>
              </Row>
            </Space>
          </Col>
          <Col span={12} >
            <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
          </Col>
        </Row>
      </DesktopView>
    </Wrapper>
  );
};

export default DiveSection;
