import { Row, Col, Grid } from 'antd';
import styled from 'styled-components';

import sectionImg from 'assets/Benefit/Benefits-Image-4.png';
import gradientBg2 from 'assets/gradient-bg-2.svg';
import mobileBg from 'assets/mobile-rotate-bottom.svg';
import tabletBg from 'assets/tablet-rotate-bg.svg';
import {
  StyledText,
  StyledImage,
  StyledParagraph,
  MobileView,
  ResponsiveTitleFont,
  BottomBannerBackground,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints } from 'utils/types/commons';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 8rem 10rem;
  z-index: 1;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 4rem 2rem 0 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 8rem 20rem;
  }
`;

const ParagraphWrapper = styled(Row)`
  text-align: center;
  padding-bottom: 4rem;

  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 2rem 10rem 4rem 10rem;
  }
`;

const RealTimeSection = () => {
  const screens = useBreakpoint();

  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground
            bgImage={screens.xs ? mobileBg : tabletBg}
            $size={`${screens.xs ? 'cover' : 'contain'}`}
            $position="top"
          >
            <Wrapper>
              <Row align="middle" justify="center">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledImage src={sectionImg} preview={false} height="100%" width="80%" />
                </Col>
              </Row>
              <Row align="middle" justify="center" style={{ textAlign: 'center' }}>
                <Col>
                  <ResponsiveTitleFont $size="52">
                    Real-Time{' '}
                    <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
                      Reporting.
                    </ResponsiveTitleFont>
                  </ResponsiveTitleFont>
                </Col>
              </Row>
              <ParagraphWrapper align="middle" justify="center">
                <StyledParagraph $weight="400" $lineHeight="28">
                  Get clarity on your sales & marketing efforts by consolidating all your analytics into a single, easy-to-use
                  platform. Whether you want to monitor performance trends, identify your most valuable customers, or compare
                  revenue by channel, it’s all possible with our comprehensive real-time reporting.
                </StyledParagraph>
              </ParagraphWrapper>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground bgImage={gradientBg2}  $size={`${screens.xs ? 'cover' : 'contain'}`} $position="top">
        <Wrapper>
          <Row align="middle" justify="center">
            <Col>
              <StyledText $size="52">
                Real-Time{' '}
                <StyledText $color={COLORS.SECONDARY_500} $size="52">
                  Reporting.
                </StyledText>
              </StyledText>
            </Col>
          </Row>
          <Row
            align="middle"
            justify="center"
            style={{ padding: '2rem 10rem 4rem 10rem', textAlign: 'center', paddingBottom: '4rem' }}
          >
            <StyledParagraph $weight="400" $lineHeight="28">
              Get clarity on your sales & marketing efforts by consolidating all your analytics into a single, easy-to-use
              platform. Whether you want to monitor performance trends, identify your most valuable customers, or compare
              revenue by channel, it’s all possible with our comprehensive real-time reporting.
            </StyledParagraph>
          </Row>
          <Row align="middle" justify="center">
            <Col span={20}>
              <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
            </Col>
          </Row>
        </Wrapper>
      </BottomBannerBackground>
    );
  };
  return renderContent();
};

export default RealTimeSection;
