import { Col, Row } from 'antd';
import { DesktopView, MobileView, StyledText } from 'components/common/styled';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 14px 10rem;

  @media (max-width: ${Breakpoints.xl}) {
    padding: 14px 5rem;
  }

  @media (max-width: ${Breakpoints.lg}) {
    padding: 14px 1rem;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    padding: 14px 20rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 1rem;
`;

const SubFooter = () => {
  const registrationDate = '© Match Fit Pass ® 2022-2023';
  const description = 'Match Fit Pass is a Verified Technologies Ltd application company registration No. 1192137';

  const renderDescription = () => {
    return (
      <StyledText $size="14" $lineHeight={20}>
        {description}
      </StyledText>
    );
  };

  const renderRegistration = () => {
    return (
      <StyledText $size="14" $lineHeight={20}>
        {registrationDate}
      </StyledText>
    );
  };
  return (
    <Wrapper>
      <DesktopView>
        <Row align="middle">
          <Col span={6}>{renderRegistration()}</Col>
          <Col span={18}>
            <Row justify="end">{renderDescription()}</Row>
          </Col>
        </Row>
      </DesktopView>
      <MobileView>
        <TextWrapper>
          {renderRegistration()}
          <div style={{ maxWidth: '30rem' }}>{renderDescription()}</div>
        </TextWrapper>
      </MobileView>
    </Wrapper>
  );
};

export default SubFooter;
