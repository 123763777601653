import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

import sectionImg from 'assets/Technology/TechnologySection2.png';
import {
  StyledText,
  StyledImage,
  DesktopView,
  MobileView,
  ResponsiveTitleFont,
  StyledParagraph,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 3rem 5rem;

  @media only screen and (max-width: ${Breakpoints.md}) {
    padding: 3rem 5rem;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    padding: 5rem 12rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 6rem 20rem;
  }
`;

const SqrcSection = () => {
  return (
    <Wrapper>
      <DesktopView>
        <Row align="middle">
          <Col span={10}>
            <Space align="center">
              <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
            </Space>
          </Col>
          <Col offset={1} span={13} style={{ padding: '32px' }}>
            <Space direction="vertical" size={16}>
              <Row>
                <Col>
                  <StyledText $size="52" style={{ whiteSpace: 'nowrap' }}>
                    What is an{' '}
                    <StyledText $color={COLORS.SECONDARY_500} $size="52">
                      SQRC?{' '}
                    </StyledText>
                  </StyledText>
                </Col>
              </Row>

              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Space direction="vertical" size={32}>
                    <StyledText $weight="400" $lineHeight="28">
                      The Secure QR Code was invented by DENSO Wave.
                    </StyledText>
                    <StyledText $weight="400" $lineHeight="28">
                      To the naked eye, an SQRC looks exactly like a standard QR code. However, an SQRC can hold both public and
                      private information.
                    </StyledText>
                    <StyledText $weight="400" $lineHeight="28">
                      Whilst a standard QR code can be read by all devices and is not secure, an SQRC can make certain
                      information private by hiding it with a cryptographic key. This ensures that only certain people or
                      certain devices can access sensitive information.
                    </StyledText>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </DesktopView>

      <MobileView>
        <Row align="middle">
          <Col style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="90%" />
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <Space direction="vertical" size={16}>
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <ResponsiveTitleFont $size="52">
                    What is an{' '}
                    <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
                      SQRC?{' '}
                    </ResponsiveTitleFont>
                  </ResponsiveTitleFont>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Space direction="vertical" size={32}>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      The Secure QR Code was invented by DENSO Wave.
                    </StyledParagraph>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      To the naked eye, an SQRC looks exactly like a standard QR code. However, an SQRC can hold both public and
                      private information.
                    </StyledParagraph>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      Whilst a standard QR code can be read by all devices and is not secure, an SQRC can make certain
                      information private by hiding it with a cryptographic key. This ensures that only certain people or
                      certain devices can access sensitive information.
                    </StyledParagraph>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </MobileView>
    </Wrapper>
  );
};

export default SqrcSection;
