import { Row } from 'antd';
import styled from 'styled-components';

import { StyledText, ResponsiveTitleFont } from 'components/common/styled';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 80px 120px;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 1rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 2rem 20rem;
  }
`;

const ParagraphWrapper = styled(Row)`
  text-align: center;
  padding-bottom: 4rem;

  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 2rem 10rem 4rem 10rem;
  }
`;

const DensoSection = () => {
  return (
    <Wrapper>
      <Row align="middle" justify="center" style={{ textAlign: 'center' }}>
        <ResponsiveTitleFont $size="52">
          Who are{' '}
          <ResponsiveTitleFont $color="#57CD66" $size="52">
            DENSO Wave?
          </ResponsiveTitleFont>
        </ResponsiveTitleFont>
      </Row>
      <ParagraphWrapper align="middle" justify="center">
        <StyledText $weight="400" $lineHeight="28">
          DENSO Wave are the company behind the SQRC technology. They are part of the Toyota Group. They also invented the QR
          code back in 1994.
        </StyledText>
      </ParagraphWrapper>
    </Wrapper>
  );
};

export default DensoSection;
