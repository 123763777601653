import { Col, Row, Grid } from 'antd';
import { COLORS } from 'utils/constants/colors';
import { ResponsiveTitleFont, BottomBannerBackground } from 'components/common/styled';
import gradientBg4 from 'assets/gradient-bg-4.svg';
import mobileBg from 'assets/mobile-gradient-bg-1.svg';
import tabletBg from 'assets/tablet-gradient-bg-2.svg';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';

const { useBreakpoint } = Grid;

const Content = ({ page }: { page: string }) => {
  const screens = useBreakpoint();
  return (
    <Row align="middle" justify="center">
      <Col span={24}>
        {page === 'terms' ? (
          <ResponsiveTitleFont $size={`${screens.xs ? '46' : 60}`}>
            Terms of{' '}
            <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size={`${screens.xs ? '46' : 60}`}>
              Use
            </ResponsiveTitleFont>
          </ResponsiveTitleFont>
        ) : (
          <ResponsiveTitleFont $size={`${screens.xs ? '46' : 60}`}>
            Privacy{' '}
            <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size={`${screens.xs ? '46' : 60}`}>
              Policy
            </ResponsiveTitleFont>
          </ResponsiveTitleFont>
        )}
      </Col>
    </Row>
  );
};

const Wrapper = styled.div`
  padding-top: 6rem;
  display: flex;
  align-content: center;
  align-items: center;
  height: 22rem;

  @media only screen and (max-width: ${Breakpoints.md}) {
    height: 22rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}) {
    height: 22rem;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    height: 24rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    height: 46vh;
    padding-top: 6rem;
  }
`;

const PolicyHeader = ({ page }: { page: string }) => {
  const screens = useBreakpoint();
  if (screens.xs) {
    // mobile
    return (
      <BottomBannerBackground bgImage={mobileBg} $size="cover" $height="22rem">
        <div style={{ paddingTop: '8rem', textAlign: 'center' }}>
          <Content page={page} />
        </div>
      </BottomBannerBackground>
    );
  }

  return (
    <BottomBannerBackground bgImage={screens.sm && !screens.lg ? tabletBg : gradientBg4} $size="cover">
      <Wrapper>
        <Content page={page} />
      </Wrapper>
    </BottomBannerBackground>
  );
};

export default PolicyHeader;
