import Composer from 'contexts/Composer';
import DashboardContextProvider from 'contexts/DashboardContext';
import AppRoutes from 'routers';

const App = () => {
  return (
    <Composer contexts={[DashboardContextProvider]}>
      <AppRoutes />
    </Composer>
  );
};

export default App;
