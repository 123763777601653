import { StyledContent } from 'components/common/styled';
import { TermsContent } from 'components/Terms';
import { PolicyHeader } from 'components/Policy';
const TermsOfUse = () => {
  return (
    <StyledContent>
      <PolicyHeader page="terms" />
      <TermsContent />
    </StyledContent>
  );
};

export default TermsOfUse;
