import { StyledContent } from 'components/common/styled';
import { Partners } from 'components/LandingPage';
import { DensoSection, SafeSection, SqrcSection, TechBanner } from 'components/Technology';

const Technology = () => {
  return (
    <StyledContent>
      <TechBanner />
      <SqrcSection />
      <DensoSection />
      <SafeSection />
      <Partners showBanner={false} />
    </StyledContent>
  );
};

export default Technology;
