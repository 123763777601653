import { Col, Row, Space } from 'antd';
import {
  StyledText,
  StyledImage,
  StyledParagraph,
  ResponsiveTitleFont,
  DesktopView,
  MobileView,
} from 'components/common/styled';
import sectionImg from 'assets/LandingPage/SectionThree.png';
import { COLORS } from 'utils/constants/colors';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';
import preEventImg from 'assets/LandingPage/mobile-pre-event.svg';
import duringEventImg from 'assets/LandingPage/mobile-during-event.svg';
import postEventImg from 'assets/LandingPage/mobile-post-event.svg';

const Wrapper = styled.div`
  padding: 60px 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 3rem 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 60px 20rem;
  }
`;

const SolutionSection = () => {
  return (
    <Wrapper>
      <Space direction="vertical" size={32}>
        <Row align="middle" justify="center">
          <ResponsiveTitleFont $size="52" style={{ textAlign: 'center' }}>
            The{' '}
            <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52" style={{ textAlign: 'center' }}>
              Complete Solution
            </ResponsiveTitleFont>
          </ResponsiveTitleFont>
        </Row>

        <MobileView>
          <Space direction="vertical" size={32}>
            <Row align="middle" justify="center">
              <Col>
                <StyledImage src={preEventImg} preview={false} height="100%" width="100%" />
              </Col>

              <Col>
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <ResponsiveTitleFont $color={COLORS.SECONDARY_500} style={{ fontSize: 28 }} $weight="700" $lineHeight="28">
                    Pre Event
                  </ResponsiveTitleFont>
                  {[
                    'Push any pre-event information (Travel issues, Entrance or turnstile queues etc)',
                    'Advertise any event promotions – F&B or merchandise',
                    'Push promo codes – anything associated with the above',
                    'Early bird offers',
                    'Challenge 25: Not a problem! We pre-verify the age & identity of every customer during the onboarding process',
                    'Tickets to preauthorised persons only',
                    'Genuine tickets, Genuine fans – No Touts or unauthorised reselling',
                    '100% Identity of every customer',
                    'Ticket packages – Single, Multiple & season ticket deals',
                    'Secure fan ticket exchange',
                    'Interactive seating plan',
                    'Cashless event- same day cash ',
                  ].map((text, index) => {
                    return (
                      <StyledParagraph key={index} $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    );
                  })}
                </Space>
              </Col>
            </Row>

            <Row align="middle" justify="center">
              <Col>
                <StyledImage src={duringEventImg} preview={false} height="100%" width="100%" />
              </Col>

              <Col>
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <ResponsiveTitleFont $color={COLORS.SECONDARY_500} style={{ fontSize: 28 }} $weight="700" $lineHeight="28">
                    During the Event
                  </ResponsiveTitleFont>
                  {[
                    'Track your customer by Entrance, Turnstile, or scanner',
                    'View live analytics of attendee numbers via custom built admin dashboard',
                    'Segment your supporters and create personalised offers or distribute promo codes',
                    'Push notifications / safety / marketing messages',
                    'Connected directly to the control room*',
                    'Monitor customers spend',
                    'Understand the customers buying interests / Trends',
                  ].map((text, index) => {
                    return (
                      <StyledParagraph key={index} $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    );
                  })}
                </Space>
              </Col>
            </Row>

            <Row align="middle" justify="center">
              <Col>
                <StyledImage src={postEventImg} preview={false} height="100%" width="100%" />
              </Col>

              <Col>
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <ResponsiveTitleFont $color={COLORS.SECONDARY_500} style={{ fontSize: 28 }} $weight="700" $lineHeight="28">
                    Post Event
                  </ResponsiveTitleFont>
                  {[
                    'Create a survey – Venue can learn from customer experience (giving an enhanced customer experience)',
                    'Push information on next game or event',
                    'Ticket promotions',
                    'Revenue in your bank same or next day! ',
                  ].map((text, index) => {
                    return (
                      <StyledParagraph key={index} $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    );
                  })}
                </Space>
              </Col>
            </Row>
          </Space>
        </MobileView>

        <DesktopView>
          <Row align="middle" justify="center" gutter={8}>
            <Col lg={{ span: 9 }} xl={{ span: 10 }}>
              <Row style={{ textAlign: 'right' }}>
                <Col span={24}>
                  <StyledText $color={COLORS.SECONDARY_500} $size={'24'} $weight="700" $lineHeight="28">
                    Pre Event
                  </StyledText>
                </Col>
                {[
                  'Push any pre-event information (Travel issues, Entrance or turnstile queues etc)',
                  'Advertise any event promotions – F&B or merchandise',
                  'Push promo codes – anything associated with the above',
                  'Early bird offers',
                  'Challenge 25: Not a problem! We pre-verify the age & identity of every customer during the onboarding process',
                  'Tickets to preauthorised persons only',
                  'Genuine tickets, Genuine fans – No Touts or unauthorised reselling',
                  '100% Identity of every customer',
                  'Ticket packages – Single, Multiple & season ticket deals',
                  'Secure fan ticket exchange',
                  'Interactive seating plan',
                  'Cashless event- same day cash ',
                ].map((text, index) => {
                  return (
                    <Col key={index} span={24} style={{ padding: '8px 0' }}>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col lg={{ span: 6 }} xl={{ span: 4 }}>
              <Row align="middle" justify="center">
                <StyledImage src={sectionImg} preview={false} height="44rem" width="auto" />
              </Row>
            </Col>
            <Col lg={{ span: 9 }} xl={{ span: 10 }}>
              <Row>
                <Col span={16}>
                  <StyledText
                    $color={COLORS.SECONDARY_500}
                    $size={'24'}
                    $weight="700"
                    $lineHeight="28"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    During the Event
                  </StyledText>
                </Col>
                {[
                  'Track your customer by Entrance, Turnstile, or scanner',
                  'View live analytics of attendee numbers via custom built admin dashboard',
                  'Segment your supporters and create personalised offers or distribute promo codes',
                  'Push notifications / safety / marketing messages',
                  'Connected directly to the control room*',
                  'Monitor customers spend',
                  'Understand the customers buying interests / Trends',
                ].map((text, index) => {
                  return (
                    <Col key={index} span={24} style={{ padding: '8px 0' }}>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    </Col>
                  );
                })}
                <Col span={12} style={{ marginTop: '3rem' }}>
                  <StyledText $color={COLORS.SECONDARY_500} $size={'24'} $weight="700" $lineHeight="28">
                    Post Event
                  </StyledText>
                </Col>
                {[
                  'Create a survey – Venue can learn from customer experience (giving an enhanced customer experience)',
                  'Push information on next game or event',
                  'Ticket promotions',
                  'Revenue in your bank same or next day! ',
                ].map((text, index) => {
                  return (
                    <Col key={index} span={24} style={{ padding: '8px 0' }}>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ margin: 0, padding: 0 }}>
                        {text}
                      </StyledParagraph>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </DesktopView>
      </Space>
    </Wrapper>
  );
};

export default SolutionSection;
