import { Layout } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'utils/constants/colors';
import { Breakpoints } from 'utils/types/commons';

export const StyledFooter = styled(Layout.Footer)`
  padding: 40px 10rem;
  background: ${COLORS.DARK_MODE_900};

  @media (max-width: ${Breakpoints.xl}) {
    padding: 40px 5rem;
  }

  @media (max-width: ${Breakpoints.lg}) {
    padding: 40px 2rem;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    padding: 40px 20rem;
  }
`