/* eslint-disable react-hooks/rules-of-hooks */
import { Row, Col, Space, Grid } from 'antd';
import { StyledText, BottomBannerBackground, StyledBullet } from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import TermsJson from './TermsOfUse.json';
import Parser from 'html-react-parser';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
const { useBreakpoint } = Grid;

const renderSectionTitle = (title: string) => {
  return (
    <StyledText $color={COLORS.SUCCESS_500} $size="32" $lineHeight="40">
      {title}
    </StyledText>
  );
};

const renderNormalText = (text: any) => {
  return (
    <StyledText $weight="400" $size="18" $lineHeight="28">
      {Parser(text)}
    </StyledText>
  );
};

const renderText700 = (text: any) => {
  return (
    <StyledText $size="18" $lineHeight="28">
      {Parser(text)}
    </StyledText>
  );
};

const renderSubSections = ({ number, title, description, isLabelLong = false, content = [] }: any) => {
  return (
    <Col span={24} style={{ paddingLeft: '1em' }}>
      <Row gutter={[8, 16]}>
        <span style={{ display: 'flex' }}>
          <div style={{ float: 'left', marginRight: '1em' }}>{renderText700(number)}</div>
          <div>
            {title && <div>{renderText700(title)}</div>}
            {Boolean(description) ? (
              <div>{renderNormalText(description)}</div>
            ) : (
              content.map((subContent: any) => {
                const { type } = subContent;
                const itemNo = isNaN(number) ? number : number - 1;
                return renderContent(type, subContent, itemNo);
              })
            )}
          </div>
        </span>
      </Row>
    </Col>
  );
};

const renderBulletList = (lists: any) => {
  return (
    <Col span={24}>
      {lists.map((list: any, i: number) => {
        return (
          <Row gutter={[8, 16]} key={`termsBulletList${i}`}>
            <span style={{ display: 'flex' }}>
              <div style={{ float: 'left', marginRight: '2em' }}>
                <StyledBullet />
              </div>
              <div>
                <Row gutter={[0, 8]}>
                  {list.map((sub: any, j: number) => {
                    return (
                      <Col span={24} key={`termsSubBullet${j}`}>
                        {renderNormalText(sub)}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </span>
          </Row>
        );
      })}
    </Col>
  );
};

const renderContent = (type: any, subContent: any, i: number) => {
  if (type === 'text700') {
    return subContent.content.map((text: string | any) => {
      return (
        <Col span={24} key={'normal700' + i}>
          {renderText700(text)}
        </Col>
      );
    });
  }

  if (type === 'bulletList') {
    return (
      <Col span={24} key={'bullet' + i}>
        {renderBulletList(subContent.content)}
      </Col>
    );
  }

  if (type === 'subSections') {
    return subContent.content.map((sections: any, j: number) => {
      const itemNumber = +i + 1;
      return renderSubSections({
        number: `${isNaN(itemNumber) ? i : itemNumber}.${+j + 1}`,
        ...sections,
      });
    });
  }
  return subContent.content.map((text: any, i: number) => {
    return (
      <Col span={24} key={'normal' + i}>
        {renderNormalText(text)}
      </Col>
    );
  });
};

const TermsContent = () => {
  const termsOfUse = Object.values(TermsJson);
  const screen = useBreakpoint();
  return (
    <BottomBannerBackground bgImage={screen.xs && mobileBg}>
      <Row style={{ padding: '4rem 0 12rem 0' }}>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
        >
          <Row>
            <Space direction="vertical" size={40}>
              <Col span={24}>
                <Row>
                  <Col span={24}>{renderText700('MATCH FIT PASS APPLICATION END USER AGREEMENT')}</Col>
                  <Col span={24}>{renderText700('PLEASE READ THESE LICENCE TERMS CAREFULLY')}</Col>
                </Row>
              </Col>
              <Col span={24}>
                {renderNormalText(
                  'By buying or using the App, you agree to these Terms. If you do not agree to these Terms, do not purchase or use the Application or Service.'
                )}
              </Col>
              {termsOfUse.map(({ key, label, content }, i) => {
                return (
                  <Row gutter={[0, 16]} key={key}>
                    <Col span={24}>{renderSectionTitle(`${+i + 1}. ${label}`)}</Col>
                    {content.map((subContent) => {
                      const { type } = subContent;
                      return renderContent(type, subContent, i);
                    })}
                  </Row>
                );
              })}
            </Space>
          </Row>
        </Col>
      </Row>
    </BottomBannerBackground>
  );
};

export default TermsContent;
