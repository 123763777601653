import { useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';

const overscrollOptions = {
  enable: true,
  effect: 'glow',
  damping: 0.1,
  maxOverscroll: 200,
  glowColor: '#222a2d',
};

const options = {
  damping: 0.07,
  plugins: {
    overscroll: { ...overscrollOptions },
  },
  syncCallbacks: true,
};

const Scroll = () => {
  useEffect(() => {
    const scrollElem = Scrollbar.init(document.body, options);
    scrollElem.addListener((status) => {
      let offset = status.offset;
      const fixedElem = document.getElementById('fixed-element');

      if (fixedElem) {
        fixedElem.style.transform = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
        fixedElem.style.background = offset.y > 0 ? 'rgba(28, 28, 37, 0.97)' : 'transparent';
      }
    });

    return () => {
      if (Scrollbar) Scrollbar.destroy(document.body);
    };
  }, []);

  return null;
};

export default Scroll;
