import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

import sectionImg from 'assets/Benefit/Benefits-Image-2.png';
import {
  StyledText,
  StyledImage,
  StyledParagraph,
  DesktopView,
  MobileView,
  ResponsiveTitleFont,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 5rem 10rem 0 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 5rem 20rem 0 20rem;
  }
`;

const ExperienceSection = () => {
  return (
    <Wrapper>
      <Space direction="vertical" size={32}>
        <DesktopView>
          <Row align="middle" justify="center">
            <Col span={16} style={{ textAlign: 'center' }}>
              <Space direction="vertical" size={16}>
                <ResponsiveTitleFont $size="48">
                  Seamless{' '}
                  <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="48">
                    Booking Experiences.
                  </ResponsiveTitleFont>
                </ResponsiveTitleFont>

                <StyledParagraph $weight="400" $lineHeight="28">
                  We provide high-fidelity 3D-models and 2D-interactive mapping during the booking process, allowing your
                  customers to visualise your venue in more granular detail before selecting their block/seat.
                </StyledParagraph>
              </Space>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col span={15}>
              <StyledImage src={sectionImg} preview={false} height="90%" width="90%" />
            </Col>
            <Col span={8}>
              <Row>
                <Col>
                  <Space size={16} direction="vertical">
                    <StyledParagraph $weight="700" $lineHeight="28">
                      For season tickets, should a fan be unable to attend an event, they will have two options:
                    </StyledParagraph>

                    <Space size={16} align="start">
                      <StyledText
                        style={{ padding: 0, marginBottom: '6px' }}
                        $weight={800}
                        $lineHeight={32}
                        $size={'24'}
                        $color={COLORS.SECONDARY_500}
                      >
                        1.
                      </StyledText>
                      <StyledParagraph $weight="400" $lineHeight="28">
                        <StyledParagraph $weight="700" style={{ marginBottom: 0, padding: 0 }}>
                          Gift the ticket to a friend or family member (provided they also have an MFP number).
                        </StyledParagraph>
                        The transfer will alert you and the person will be checked against banning lists before it is approved.
                      </StyledParagraph>
                    </Space>
                    <Space size={16} align="start">
                      <StyledText
                        style={{ padding: 0, marginBottom: '6px' }}
                        $weight={800}
                        $lineHeight={32}
                        $size={'24'}
                        $color={COLORS.SECONDARY_500}
                      >
                        2.
                      </StyledText>
                      <StyledParagraph $weight="400" $lineHeight="28">
                        <StyledParagraph $weight="700" style={{ marginBottom: 0, padding: 0 }}>
                          Sell the ticket back to the Venue.
                        </StyledParagraph>
                        This allows each venue to control the market for ticket resales, and ensures that real fans aren’t
                        priced out of the market by ticket touts.
                      </StyledParagraph>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </DesktopView>

        <MobileView>
          <Row align="middle" justify="center">
            <Col span={22} style={{ textAlign: 'center' }}>
              <Space direction="vertical" size={16}>
                <ResponsiveTitleFont $size="48">
                  Seamless{' '}
                  <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="48">
                    Booking Experiences.
                  </ResponsiveTitleFont>
                </ResponsiveTitleFont>

                <StyledParagraph $weight="400" $lineHeight="28">
                  We provide high-fidelity 3D-models and 2D-interactive mapping during the booking process, allowing your
                  customers to visualise your venue in more granular detail before selecting their block/seat.
                </StyledParagraph>
              </Space>
            </Col>
          </Row>
          <Row align="middle">
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledImage src={sectionImg} preview={false} height="100%" width="80%" />
            </Col>
            <Col>
              <Row>
              <Col style={{ marginRight: 20 }}>
                  <Space size={16} direction="vertical">
                    <StyledParagraph $weight="700" $lineHeight="28">
                      For season tickets, should a fan be unable to attend an event, they will have two options:
                    </StyledParagraph>

                    <Space size={16} align="start">
                      <StyledText
                        style={{ padding: 0, marginBottom: '6px' }}
                        $weight={800}
                        $lineHeight={32}
                        $size={'24'}
                        $color={COLORS.SECONDARY_500}
                      >
                        1.
                      </StyledText>
                      <StyledParagraph $weight="400" $lineHeight="28">
                        <StyledParagraph $weight="700" style={{ marginBottom: 0, padding: 0 }}>
                          Gift the ticket to a friend or family member (provided they also have an MFP number).
                        </StyledParagraph>
                        The transfer will alert you and the person will be checked against banning lists before it is approved.
                      </StyledParagraph>
                    </Space>
                    <Space size={16} align="start">
                      <StyledText
                        style={{ padding: 0, marginBottom: '6px' }}
                        $weight={800}
                        $lineHeight={32}
                        $size={'24'}
                        $color={COLORS.SECONDARY_500}
                      >
                        2.
                      </StyledText>
                      <StyledParagraph $weight="400" $lineHeight="28">
                        <StyledParagraph $weight="700" style={{ marginBottom: 0, padding: 0 }}>
                          Sell the ticket back to the Venue.
                        </StyledParagraph>
                        This allows each venue to control the market for ticket resales, and ensures that real fans aren’t
                        priced out of the market by ticket touts.
                      </StyledParagraph>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </MobileView>
      </Space>
    </Wrapper>
  );
};

export default ExperienceSection;
