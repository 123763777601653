import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import {
  StyledImage,
  StyledParagraph,
  MobileView,
  ResponsiveTitleFont,
  BottomBannerBackground,
  BannerContainer,
  ResponsiveContainer,
} from 'components/common/styled';
import sectionImg from 'assets/LandingPage/SectionOne/SectionOneImg.png';
import googlePlay from 'assets/LandingPage/SectionOne/Google_Play_Store_badge.png';
import appleStore from 'assets/LandingPage/SectionOne/App_Store_Badge.png';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints, Links } from 'utils/types/commons';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
import tabletBg from 'assets/tablet-gradient-bg.svg';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 120px 6rem 0;

  @media only screen and (max-width: ${Breakpoints.md}) {
    padding: 14rem 2rem 6rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 200px 6rem 4rem;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    padding: 140px 8rem 60px 10rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 120px 10rem 0 20rem;
  }
`;

const ImageWrapper = styled(Space)`
  @media only screen and (max-width: ${Breakpoints.xs}) {
    display: flex;
    flex-direction: column;
  }
`;

const Banner = () => {
  const screens = useBreakpoint();

  const handleRedirect = (link: string) => {
    window.open(link, '_blank');
  };

  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground bgImage={screens.xs ? mobileBg : tabletBg} $bottom="4rem">
            <Wrapper>
              <Row align="middle">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledImage preview={false} src={sectionImg} height="100%" width="100%" />
                </Col>

                <Col>
                  <Space direction="vertical" size={32} style={{ textAlign: 'center' }}>
                    <Space direction="vertical" size={16} align="center" style={{ padding: 6 }}>
                      <ResponsiveTitleFont $size={60}>
                        Experience the next generation of{' '}
                        <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size={60}>
                          event management.
                        </ResponsiveTitleFont>
                      </ResponsiveTitleFont>
                      <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                        Match Fit Pass is the world’s first fully end-to-end solution designed to transform customer experiences
                        through a combination of ultra-secure ticketing, testing & payment solutions.
                      </StyledParagraph>
                    </Space>

                    <ImageWrapper size={16}>
                      {/* Open google play store in another tab */}
                      <StyledImage
                        onClick={() => handleRedirect(Links.GOOGLE)}
                        preview={false}
                        src={googlePlay}
                        style={{ cursor: 'pointer' }}
                        height="63"
                        width="211"
                      />

                      {/* Open Apple App Store in another tab */}
                      <StyledImage
                        onClick={() => handleRedirect(Links.APPLE)}
                        preview={false}
                        src={appleStore}
                        style={{
                          cursor: 'pointer',
                        }}
                        height="63"
                        width="211"
                      />
                    </ImageWrapper>
                  </Space>
                </Col>
              </Row>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground>
        <BannerContainer>
          <Wrapper>
            <Row wrap={false} align="middle" style={{ minHeight: '29.5rem' }}>
              <Col lg={12} xl={14} xxl={11}>
                <Space direction="vertical" size={32}>
                  <Space direction="vertical" size={16}>
                    <ResponsiveTitleFont $size={60} $lineHeight={72}>
                      Experience the next generation of{' '}
                      <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size={60}>
                        event management.
                      </ResponsiveTitleFont>
                    </ResponsiveTitleFont>
                    <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                      Match Fit Pass is the world’s first fully end-to-end solution designed to transform customer experiences
                      through a combination of ultra-secure ticketing, testing & payment solutions.
                    </StyledParagraph>
                  </Space>

                  <Space size={24}>
                    <StyledImage
                      onClick={() => handleRedirect(Links.GOOGLE)}
                      preview={false}
                      src={googlePlay}
                      style={{ cursor: 'pointer' }}
                      height='64'
                      width='214'
                    />

                    <StyledImage
                      onClick={() => handleRedirect(Links.APPLE)}
                      preview={false}
                      src={appleStore}
                      style={{
                        cursor: 'pointer',
                      }}
                      height='64'
                      width='214'
                    />
                  </Space>
                </Space>
              </Col>

              <Col md={{ push: 1 }} lg={{ span: 13 }} xl={{ push: 0, span: 12 }} xxl={{ span: 14 }}>
                <StyledImage preview={false} src={sectionImg} height="86%" width="86%" style={{ paddingTop: '2rem' }} />
              </Col>
            </Row>
          </Wrapper>
        </BannerContainer>
      </BottomBannerBackground>
    );
  };

  return <ResponsiveContainer>{renderContent()}</ResponsiveContainer>;
};

export default Banner;
