import { StyledImage } from 'components/common/styled';

import facebook from 'assets/LandingPage/InfoHeader/Facebook.svg';
import linkedin from 'assets/LandingPage/InfoHeader/LinkedIn.svg';
import twitter from 'assets/LandingPage/InfoHeader/Twitter.svg';
import instagram from 'assets/LandingPage/InfoHeader/Instagram.svg';

const Socials = ({ source = '' }) => {
  const navigateTo = (link: string) => {
    window.open(link, '_blank');
  };
  const links = [
    {
      source: facebook,
      path: 'https://www.facebook.com/people/Match-Fit-Pass/100064085677528',
    },
    {
      source: linkedin,
      path: 'https://www.linkedin.com/company/match-fit-pass',
    },
    {
      source: twitter,
      path: 'https://twitter.com/matchfitpass',
    },
    {
      source: instagram,
      path: 'https://www.instagram.com/matchfitpass/',
    },
  ];
  return (
    <span style={{ display: 'flex' }}>
      {links.map((link) => {
        return (
          <div style={{ padding: '0 4px' }}>
            <StyledImage
              src={link.source}
              style={{ cursor: 'pointer' }}
              preview={false}
              onClick={() => navigateTo(link.path)}
              height={source === 'footer' ? 32 : 20}
              width={source === 'footer' ? 32 : 20}
            />
          </div>
        );
      })}
    </span>
  );
};

export default Socials;
