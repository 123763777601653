import { BannerSection, ExperienceSection, GettingSection, RealTimeSection } from 'components/Benefit';
import { StyledContent } from 'components/common/styled';

const Benefits = () => {
  return (
    <StyledContent>
      <BannerSection />
      <ExperienceSection />
      <GettingSection />
      <RealTimeSection />
    </StyledContent>
  );
};

export default Benefits;
