import { Layout } from 'antd';
import InfoHeader from 'components/InfoHeader';
import { COLORS } from 'utils/constants/colors';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import SubFooter from 'components/SubFooter';
import { StyledDivider } from 'components/common/styled';
import Scroll from 'components/SmoothScroll/Scroll';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  z-index: 10;
  position: fixed;
  width: 100%;
`;

const Main = () => {
  return (
    <Layout style={{ backgroundColor: COLORS.DARK_MODE_900 }}>
      <Scroll />
      <HeaderContainer id="fixed-element">
        <InfoHeader />
        <StyledDivider />
        <Header />
      </HeaderContainer>

      {/* Dynamic Page */}
      <Outlet />

      <Footer />
      <StyledDivider />
      <SubFooter />
    </Layout>
  );
};

export default Main;
