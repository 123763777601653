import { Col, Row, Space } from 'antd';
import {
  StyledImage,
  StyledButton,
  StyledText,
  StyledParagraph,
  DesktopView,
  MobileView,
  MobileButtonWrapper,
  ResponsiveTitleFont,
} from 'components/common/styled';

import sectionImg from 'assets/LandingPage/SectionTwo.png';
import { COLORS } from 'utils/constants/colors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';
import { scrollToTop } from 'utils/scroll/scrollTop';

const Wrapper = styled.div`
  padding: 6rem 10rem 3rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 4rem 2rem 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 6rem 20rem;
  }
`;

const PlugPlay = () => {
  const navigate = useNavigate();
  const handleNavigate = (link: string) => {
    navigate(link);
    scrollToTop();
  };

  return (
    <Wrapper>
      <MobileView>
        <Row align="middle">
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
          </Col>

          <Col>
            <Space direction="vertical" size={36} style={{ textAlign: 'center' }}>
              <ResponsiveTitleFont $size={'52'}>
                Plug in &{' '}
                <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size={'52'}>
                  Play
                </ResponsiveTitleFont>
              </ResponsiveTitleFont>

              <Space direction="vertical" size={16} align="center">
                <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                  Elevate your customer journey with a fully branded application interface to match the look & feel of your
                  business.
                </StyledParagraph>

                <MobileButtonWrapper>
                  <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost">
                    The Technology
                  </StyledButton>
                  <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                    The Benefits
                  </StyledButton>
                </MobileButtonWrapper>
              </Space>
            </Space>
          </Col>
        </Row>
      </MobileView>

      <DesktopView>
        <Row align="middle">
          <Col span={14} xxl={16}>
            <StyledImage src={sectionImg} preview={false} height="100%" width="100%" />
          </Col>
          <Col span={10} xxl={8} style={{ padding: '10px' }}>
            <Space direction="vertical" size={16}>
              <Row>
                <Col>
                  <StyledText $size={'52'}>
                    Plug in &{' '}
                    <StyledText $color={COLORS.SUCCESS_500} $size={'52'}>
                      Play
                    </StyledText>
                  </StyledText>
                </Col>
              </Row>

              <Space direction="vertical" size={32}>
                <Row>
                  <Col style={{ marginRight: 30 }}>
                    <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
                      Elevate your customer journey with a fully branded application interface to match the look & feel of your
                      business.
                    </StyledParagraph>
                  </Col>
                </Row>

                <Row>
                <span style={{ whiteSpace: 'nowrap' }}>
                    <StyledButton onClick={() => handleNavigate('/technology')} $variant="ghost" >
                      The Technology
                    </StyledButton>
                    <StyledButton onClick={() => handleNavigate('/benefits')} $variant="primary">
                      The Benefits
                    </StyledButton>
                  </span>
                </Row>
              </Space>
            </Space>
          </Col>
        </Row>
      </DesktopView>
    </Wrapper>
  );
};

export default PlugPlay;
