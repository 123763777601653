import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import { InfoMail } from 'components/common/InfoMail';
import { Socials } from 'components/common/Socials';
import { StyledText, StyledButton, DesktopView, MobileView } from 'components/common/styled';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  row-gap: 1rem;
`;

const Demo = () => {
  const screens = useBreakpoint();
  const handleDemo = () => {
    // @ts-ignore: type not found in window
    window.Intercom('show');
  };

  const useDynamicButtonWidth = () => {
    let width = 0;
    if (screens.sm) {
      width = 138;
    } else {
      width = 400;
    }
    return width;
  };

  const dynamicButtonWidth = useDynamicButtonWidth();

  return (
    <>
      <DesktopView>
        <Row style={{ paddingLeft: 12 }}>
          <Space direction="vertical" size={16}>
            <Col span={20} xl={24}>
              <StyledText $weight="700" $size={screens.xl ? '18' : '14'} $lineHeight="28">
                Looking to take your event experiences to the next level?
              </StyledText>
            </Col>
            <Col span={24}>
              <StyledButton onClick={handleDemo} $variant="success" $width={138} $height={48} $margin={1}>
                Book a Demo
              </StyledButton>
            </Col>
            <Col span={24}>
              <InfoMail />
            </Col>
          </Space>
        </Row>
      </DesktopView>

      <MobileView>
        <Wrapper>
          <StyledText $weight="700" $size="18" $lineHeight="28">
            Looking to take your event <br /> experiences to the next level?
          </StyledText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledButton onClick={handleDemo} $variant="success" $width={dynamicButtonWidth} $height={48} $margin={1}>
              Book a Demo
            </StyledButton>
          </div>
          <InfoMail />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Socials source="footer" />
          </div>
        </Wrapper>
      </MobileView>
    </>
  );
};

export default Demo;
