import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';
import sectionImg from 'assets/Technology/TechnologySection1.png';
import densoSolution from 'assets/Technology/Denso-Wave-SQRC-Solutions.png';
import {
  StyledImage,
  StyledParagraph,
  MobileView,
  ResponsiveContainer,
  ResponsiveTitleFont,
  BottomBannerBackground,
  BannerContainer,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
import tabletBg from 'assets/tablet-gradient-bg.svg';
import { Breakpoints } from 'utils/types/commons';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 120px 3rem 6rem 6rem;

  @media only screen and (max-width: ${Breakpoints.md}) {
    padding: 14rem 2rem 6rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 200px 4rem 7rem 6rem;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    padding: 160px 8rem 60px 10rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 120px 20rem 0;
  }
`;

const TechBanner = () => {
  const screens = useBreakpoint();
  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground bgImage={screens.xs ? mobileBg : tabletBg} $size='cover'>
            <Wrapper>
              <Row align="middle" gutter={[16, 16]}>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledImage preview={false} src={sectionImg} height="100%" width="80%" />
                </Col>

                <Col>
                  <Space direction="vertical" size={16} style={{ textAlign: 'center' }}>
                    <ResponsiveTitleFont $size="60">
                      The{' '}
                      <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="60">
                        Technology.
                      </ResponsiveTitleFont>
                    </ResponsiveTitleFont>
                    <StyledParagraph $weight="400" $lineHeight="28">
                      Match Fit Pass ® is the world’s first & only application to use Secure QR Code (SQRC) technology, teamed
                      with biometric identification software. This makes us the most secure event management solution available
                      on the market.
                    </StyledParagraph>
                    <StyledImage preview={false} src={densoSolution} height="91" width="302" />
                  </Space>
                </Col>
              </Row>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground>
        <BannerContainer>
          <Wrapper>
            <Row wrap={false} align="middle" style={{ minHeight: '28rem' }}>
              <Col lg={12} xl={12} xxl={12}>
                <Space direction="vertical" size={16} style={{ textAlign: 'justify', marginRight: '0' }}>
                  <ResponsiveTitleFont $size={60}>
                    The{' '}
                    <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="60">
                      Technology.
                    </ResponsiveTitleFont>
                  </ResponsiveTitleFont>
                  <StyledParagraph $weight="400" $lineHeight="28">
                    Match Fit Pass ® is the world’s first & only application to use Secure QR Code (SQRC) technology, teamed
                    with biometric identification software. This makes us the most secure event management solution available on
                    the market.
                  </StyledParagraph>
                  <StyledImage preview={false} src={densoSolution} height="80" width="286" />
                </Space>
              </Col>

              <Col xxl={{ span: 12}} xl={{ span: 10, push: 2 }}>
                <StyledImage preview={false} src={sectionImg} height="90%" width="90%" />
              </Col>
            </Row>
          </Wrapper>
        </BannerContainer>
      </BottomBannerBackground>
    );
  };

  return <ResponsiveContainer>{renderContent()}</ResponsiveContainer>;
};

export default TechBanner;
