import { Col, Row, Grid } from 'antd';
import styled from 'styled-components';

import denso from 'assets/LandingPage/Denso.png';
import mobileDenso from 'assets/LandingPage/mobileDenso.png';
import zkteco from 'assets/LandingPage/ZKTeco.png';
import mobileZkteco from 'assets/LandingPage/mobileZKTeco.png';
import {
  StyledImage,
  StyledParagraph,
  ResponsiveTitleFont,
  MobileView,
  BottomBannerBackground,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints, Links } from 'utils/types/commons';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
import tabletBg from 'assets/tablet-gradient-bg.svg';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 5rem 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 2rem 2rem 8rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 4rem 20rem 10rem 20rem;
  }
`;

const ParagraphWrapper = styled(Row)`
  text-align: center;
  padding-bottom: 4rem;
  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 2rem;
  }
  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 2rem 20rem;
  }
`;

const ImageWrapper = styled(Row)`
  justify-content: center;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledCol = styled(Col)`
  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

const Partners = ({ showBanner = true }: { showBanner?: boolean }) => {
  const screens = useBreakpoint();
  const handleRedirect = (link: string) => {
    window.open(link, '_blank');
  };

  const renderContent = () => {
    return (
      <Wrapper>
        <Row align="middle" justify="center">
          <ResponsiveTitleFont $size="52">
            Our{' '}
            <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
              Partners
            </ResponsiveTitleFont>
          </ResponsiveTitleFont>
        </Row>
        <ParagraphWrapper align="middle" justify="center">
          <StyledParagraph $weight="400" $lineHeight="28" style={{ marginBottom: 0 }}>
            They say if you want to go fast, go alone. But if you want to go far, go together. That’s why we’re proud to work in
            partnership with some of the most innovative companies and organisations across technology, health & education.
          </StyledParagraph>
        </ParagraphWrapper>

        <ImageWrapper>
          <StyledCol>
            <StyledImage
              onClick={() => handleRedirect(Links.DENSO)}
              style={{ cursor: 'pointer' }}
              preview={false}
              src={screens.sm ? denso : mobileDenso}
              height={screens.sm ? "100": "80"}
              width={screens.sm ? "240": "320"}
            />
          </StyledCol>
          <StyledCol>
            <StyledImage
              onClick={() => handleRedirect(Links.ZKTECO)}
              style={{ cursor: 'pointer' }}
              preview={false}
              src={screens.sm ? zkteco : mobileZkteco}
              height={screens.sm ? "100": "80"}
              width={screens.sm ? "240": "320"}
            />
          </StyledCol>
        </ImageWrapper>
      </Wrapper>
    );
  };

  if (screens.xs || (screens.sm && !screens.lg)) {
    // mobile
    return (
      <MobileView>
        {showBanner ? (
          <BottomBannerBackground
            bgImage={screens.xs ? mobileBg : tabletBg}
            $size='cover'
            $topMargin="-50vh"
          >
            <div style={{ paddingTop: '50vh' }}>{renderContent()}</div>
          </BottomBannerBackground>
        ) : (
          renderContent()
        )}
      </MobileView>
    );
  }

  return showBanner ? <BottomBannerBackground $size='contain' $bottom='6rem' $topMargin='-50rem'>  <div style={{ paddingTop: '50rem' }}>{renderContent()}</div></BottomBannerBackground> : renderContent();
};

export default Partners;
