import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import sectionImg from 'assets/Benefit/Benefits-Image-1.png';
import googlePlay from 'assets/LandingPage/SectionOne/Google_Play_Store_badge.png';
import appleStore from 'assets/LandingPage/SectionOne/App_Store_Badge.png';
import {
  StyledImage,
  StyledParagraph,
  MobileView,
  ResponsiveContainer,
  ResponsiveTitleFont,
  BottomBannerBackground,
  BannerContainer,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints, Links } from 'utils/types/commons';
import mobileBg from 'assets/mobile-gradient-bottom.svg';
import tabletBg from 'assets/tablet-gradient-bg.svg';

const { useBreakpoint } = Grid;

const ImageWrapper = styled(Space)`
  @media only screen and (max-width: ${Breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  padding: 120px 6rem 0;

  @media only screen and (max-width: ${Breakpoints.md}) {
    padding: 14rem 2rem 6rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}) {
    padding: 200px 4rem 3rem 6rem;
  }

  @media only screen and (min-width: ${Breakpoints.xl}) {
    padding: 160px 10rem 30px;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 120px 24rem 0 20rem;
  }
`;

const BannerSection = () => {
  const screens = useBreakpoint();

  const handleRedirect = (link: string) => {
    window.open(link, '_blank');
  };

  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground bgImage={screens.xs ? mobileBg : tabletBg} $size='cover'>
            <Wrapper>
              <Row align="middle">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledImage preview={false} src={sectionImg} height="100%" width="80%" />
                </Col>

                <Col>
                  <Space direction="vertical" size={32} style={{ paddingBottom: '5rem', textAlign: 'center' }}>
                    <Space direction="vertical" size={16} align="center" style={{ padding: 6 }}>
                      <ResponsiveTitleFont $size="60">
                        The{' '}
                        <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size="60">
                          Benefits.
                        </ResponsiveTitleFont>
                      </ResponsiveTitleFont>

                      <StyledParagraph $weight="400" $lineHeight="28">
                        Match Fit Pass ® provides venues with a bespoke, white-labelled ticketing storefront that facilitates
                        the full browsing, selection and payment process for single event tickets, season tickets, subscriptions
                        or upgrades.
                      </StyledParagraph>
                    </Space>

                    <ImageWrapper size={16}>
                      {/* Open google play store in another tab */}
                      <StyledImage
                        onClick={() => handleRedirect(Links.GOOGLE)}
                        preview={false}
                        src={googlePlay}
                        style={{ cursor: 'pointer' }}
                        height="72"
                        width="240"
                      />

                      {/* Open Apple App Store in another tab */}
                      <StyledImage
                        onClick={() => handleRedirect(Links.APPLE)}
                        preview={false}
                        src={appleStore}
                        style={{
                          cursor: 'pointer',
                        }}
                        height="72"
                        width="240"
                      />
                    </ImageWrapper>
                  </Space>
                </Col>
              </Row>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground>
        <BannerContainer>
          <Wrapper>
            <Row wrap={false} align="middle" gutter={[26, 0]} style={{ minHeight: '30rem' }}>
              <Col lg={12} xl={14} xxl={13}>
                <Space direction="vertical" size={16} style={{ paddingBottom: '5rem' }}>
                  <Space direction="vertical" size={16} align="start" style={{ padding: 6 }}>
                    <ResponsiveTitleFont $size={60}>
                      The{' '}
                      <ResponsiveTitleFont $color={COLORS.SUCCESS_500} $size="60">
                        Benefits.
                      </ResponsiveTitleFont>
                    </ResponsiveTitleFont>

                    <StyledParagraph $weight="400" $lineHeight="28">
                      Match Fit Pass ® provides venues with a bespoke, white-labelled ticketing storefront that facilitates the
                      full browsing, selection and payment process for single event tickets, season tickets, subscriptions or
                      upgrades.
                    </StyledParagraph>
                  </Space>

                  <Space size={24}>
                    <StyledImage
                      onClick={() => handleRedirect(Links.GOOGLE)}
                      preview={false}
                      src={googlePlay}
                      style={{ cursor: 'pointer' }}
                      height="64"
                      width="214"
                    />

                    <StyledImage
                      onClick={() => handleRedirect(Links.APPLE)}
                      preview={false}
                      src={appleStore}
                      style={{
                        cursor: 'pointer',
                      }}
                      height="64"
                      width="214"
                    />
                  </Space>
                </Space>
              </Col>

              <Col xl={{ span: 11 }} xxl={{ span: 14 }}>
                <StyledImage preview={false} src={sectionImg} height="94%" width="94%" />
              </Col>
            </Row>
          </Wrapper>
        </BannerContainer>
      </BottomBannerBackground>
    );
  };
  return <ResponsiveContainer>{renderContent()}</ResponsiveContainer>;
};

export default BannerSection;
