import React from 'react';

interface ComposerProps {
  contexts: Array<React.ComponentType<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

const Composer = ({ contexts, children }: ComposerProps) => {
  return (
    <>
      {contexts.reduceRight((aggregate, Context) => {
        return <Context>{aggregate}</Context>;
      }, children)}
    </>
  );
};

export default Composer;
