import { Col, Row } from 'antd';
import { Socials } from 'components/common/Socials';
import { InfoMail } from 'components/common/InfoMail';
import styled from 'styled-components';
import { Breakpoints } from 'utils/types/commons';

const Wrapper = styled.div`
  padding: 14px 10rem;

  @media (max-width: ${Breakpoints.xl}) {
    padding: 14px 5rem;
  }

  @media (max-width: ${Breakpoints.lg}) {
    padding: 14px 2rem;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    padding: 14px 20rem;
  }
`;

const InfoHeader = () => {
  return (
    <Wrapper>
      <Row align="middle">
        <Col span={15}>
          <InfoMail />
        </Col>
        <Col span={9}>
          <Row justify="end">
            <Socials />
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default InfoHeader;
