import { Col, Row, Space, Grid } from 'antd';
import styled from 'styled-components';

import gradientBg2 from 'assets/gradient-bg-2.svg';
import sectionImg from 'assets/Technology/TechnologySection4.png';
import {
  StyledText,
  StyledImage,
  StyledParagraph,
  MobileView,
  ResponsiveTitleFont,
  BottomBannerBackground,
} from 'components/common/styled';
import { COLORS } from 'utils/constants/colors';
import { Breakpoints } from 'utils/types/commons';
import mobileBg from 'assets/mobile-rotate-bottom.svg';
import tabletBg from 'assets/tablet-rotate-bg.svg';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  padding: 4rem 10rem;

  @media only screen and (max-width: ${Breakpoints.lg}) {
    padding: 5rem 2rem 0;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}) {
    padding: 4rem 20rem;
  }
`;

const SafeSection = () => {
  const screens = useBreakpoint();
  
  const renderContent = () => {
    if (screens.xs || (screens.sm && !screens.lg)) {
      return (
        <MobileView>
          <BottomBannerBackground
            bgImage={screens.xs ? mobileBg : tabletBg}
            $size={`${screens.xs ? 'cover' : 'contain'}`}
            $position="top"
          >
            <Wrapper>
              <Row align="middle">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledImage src={sectionImg} preview={false} height="100%" width="80%" />
                </Col>
                <Col style={{ textAlign: 'center', padding: '32px' }}>
                  <Space direction="vertical" size={16}>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col>
                        <ResponsiveTitleFont $color={COLORS.SECONDARY_500} $size="52">
                          How safe <ResponsiveTitleFont $size="52">is this? </ResponsiveTitleFont>
                        </ResponsiveTitleFont>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <StyledParagraph>
                          As our SQRC technology can encrypt sensitive information, and requires a biometric signature for
                          verification, we can confidently say that the Match Fit Pass ® solution provides the highest level of
                          data security for businesses and users.
                        </StyledParagraph>
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>
            </Wrapper>
          </BottomBannerBackground>
        </MobileView>
      );
    }
    return (
      <BottomBannerBackground bgImage={gradientBg2} $size={screens.xl ? 'cover' : 'contain'} $position="top">
        <Wrapper>
          <Row align="middle">
            <Col span={12} style={{ padding: '32px' }}>
              <Space direction="vertical" size={16}>
                <Row>
                  <Col>
                    <StyledText $color={COLORS.SECONDARY_500} $size="52">
                      How safe <StyledText $size="52">is this? </StyledText>
                    </StyledText>
                  </Col>
                </Row>

                <Row>
                  <Col style={{ marginRight: 40 }}>
                    <StyledParagraph>
                      As our SQRC technology can encrypt sensitive information, and requires a biometric signature for
                      verification, we can confidently say that the Match Fit Pass ® solution provides the highest level of data
                      security for businesses and users.
                    </StyledParagraph>
                  </Col>
                </Row>
              </Space>
            </Col>
            <Col span={12}>
              <StyledImage src={sectionImg} preview={false} height="100%" width="80%" />
            </Col>
          </Row>
        </Wrapper>
      </BottomBannerBackground>
    );
  };
  return renderContent();
};

export default SafeSection;
